import React from "react";
import "./Invoices.css";
import { FormCheck, Table, Button, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useState, useEffect } from "react";
import { ORG_ID, QB_URL } from "../common/constant";
import {
  invoiceSummary,
  invoiceRegenerate,
  invoiceSend,
  invoiceSendV2,
  isRefreshTokenValid,
  attachPdfService,
  invoiceStatusUpdate,
  invoiceRegenerateV2,
  invoiceItemPreviewV2,
} from "../../service/invoiceService";
import Loader from "../common/Loader";
import DialogBoxModal from "../modal/DialogBoxModal";
import "react-toastify/dist/ReactToastify.css";
import "../common/Common.css";
import Pagination from "../common/Pagination";
import TableHeading from "../common/TableHeading";
import PdfViewModal from "../modal/PdfViewModal";
import { ACTIVEROWCOLOR } from "../common/constant";
import { customViewFormatDate, formatDate } from "../common/helper";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';

function InvoicesList(props) {
  const navigate = useNavigate();
  //for pagination
  const itemsPerpage = 25;
  const [itemOffset, setItemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(itemsPerpage);
  const [isLoadPagination, setIsLoadPagination] = useState(true);

  const [currentSortingField, setCurrentSortingField] = useState("");
  const [sortingType, setSortingType] = useState("");

  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [tokenValidationFlag, setTokenValidationFlag] = useState(false);
  const [currentInvoices, setCurrentInvoices] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isPdfViewOpen, setIsPdfViewOpen] = useState(false);
  const [isPdfViewLoader, setIsPdfViewLoader] = useState(false);
  const [linkToPdfData, setLinkToPdfData] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [startDate, endDate] = dateRange;
  const [dialogBox, setDialogBox] = useState({
    isDialogboxOpen: false,
    dialogBoxMessage: "",
    isConfirmationDialogBox: true,
    dialogBoxIcon: "delete",
    dialogboxName: "",
  });

  const fieldNamesAndLabels = {
    date: "Date",
    name: "Name",
    frequency: "Frequency",
    amount: "Amount",
    noSorting: "Preview",
    status: "Status",
  };

  useEffect(() => {
    checkRefreshTokenValidity();
  }, []);

  useEffect(() => {
    fetchInvoiceList();
  }, [props.isRefreshInvoices]);

  useEffect(() => {
    sortField(currentSortingField);
    setIsLoadPagination(false);
  }, [invoices]);

  useEffect(() => {
    setIsLoadPagination(true);
  }, [currentInvoices]);

  function sortField(sortingField) {
    switch (sortingField) {
      case "dateField":
        invoices.sort(function(a, b) {
          var nameA = a.invoiceDate; // ignore upper and lowercase
          var nameB = b.invoiceDate; // ignore upper and lowercase
          if (sortingType === "dateASC") {
            return nameA < nameB ? -1 : 1;
          } else {
            return nameA < nameB ? 1 : -1;
          }
        });
        break;
      case "nameField":
        invoices.sort(function(a, b) {
          var nameA = a.accountName.toUpperCase(); // ignore upper and lowercase
          var nameB = b.accountName.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "nameASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "frequencyField":
        invoices.sort(function(a, b) {
          var nameA = a.invoiceFrequency.toUpperCase(); // ignore upper and lowercase
          var nameB = b.invoiceFrequency.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "frequencyASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "statusField":
        invoices.sort(function(a, b) {
          var nameA = a.subStatus.toUpperCase(); // ignore upper and lowercase
          var nameB = b.subStatus.toUpperCase(); // ignore upper and lowercase
          if (sortingType === "statusASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      case "amountField":
        invoices.sort(function(a, b) {
          var nameA = a.invoiceAmount; // ignore upper and lowercase
          var nameB = b.invoiceAmount; // ignore upper and lowercase
          if (sortingType === "amountASC") {
            return nameA < nameB ? 1 : -1;
          } else {
            return nameA < nameB ? -1 : 1;
          }
        });
        break;
      default:
        break;
    }
    setSelectedItems([]);
    setCurrentInvoices(invoices.slice(itemOffset, endOffset));
  }

  async function fetchInvoiceList() {
    setLoading(true);

    setCurrentSortingField("dateField");
    setSortingType("dateDESC");

    let response = await invoiceSummary(
      props.invoiceType,
      props.accountId,
      startDate ? formatDate(startDate) : 0,
      endDate ? formatDate(endDate) : 0
    );
    if (response.status === 200) {
      setInvoices(response.data ? response.data : []);
    } else if (response.status === 404) {
      setInvoices([]);
    }
    sortField(currentSortingField);
    setLoading(false);
  }

  async function checkRefreshTokenValidity() {
    setLoading(true);
    let response = await isRefreshTokenValid();
    if (response.status === 200) {
      setTokenValidationFlag(response.data ? response.data.response : false);
    } else if (response.status === 500) {
      setTokenValidationFlag(false);
    }
    setLoading(false);
  }

  // async function connnectToQuickbook(){
  //   let toastId = props.toastLoader("Please wait...");
  //   const randomBool = Math.random() > 0.5 ? true : false;
  //   if(randomBool) console.log('button clicked');
  //   else{
  //     props.toastLoaderUpdate(toastId, "Invoice regenerating failed!", "error");
  //   }
  // }

  const updateInvoiceStatus = () => async () => {
    let toastId = props.toastLoader("Please wait...");
    setDialogBox({ isDialogboxOpen: false });
    try {
      var response = await invoiceStatusUpdate({
        accountId: props.accountId,
        invoiceNumbersToUpdate: selectedItems,
        invoiceStatus: invoiceStatus,
      });
      if (response) {
        if (response.status === 200) {
          await fetchInvoiceList();
          props.setIsRefreshInvoices(!props.isRefreshInvoices);
          props.toastLoaderUpdate(
            toastId,
            "Invoice status updated successfully!",
            "success"
          );
        } else if (typeof response == "undefined") {
          props.toastLoaderUpdate(toastId, "something went wrong", "error");
        } else {
          props.toastLoaderUpdate(toastId, "something went wrong", "error");
        }
      } else {
        let message = response.data.response
          ? response.data.response
          : "something went wrong";
        props.toastLoaderUpdate(toastId, message, "error");
      }
    } catch (error) {
      props.toastLoaderUpdate(toastId, "Internal Server Error!", "error");
    }
  };

  async function regenerateInvoice() {
    let toastId = props.toastLoader("Please wait...");
    let invoiceToRegenerate = null;
    invoices.forEach((invoice) => {
      if (invoice.invoiceNumber === selectedItems[0]) {
        invoiceToRegenerate = invoice;
      }
    });
    try {
      var response = invoiceToRegenerate.invoiceVersion === 'v1' ? await invoiceRegenerate(invoiceToRegenerate) : await invoiceRegenerateV2(invoiceToRegenerate);
      if (response.status === 200 || response.status === 201) {
        props.toastLoaderUpdate(
          toastId,
          "Invoice regeneration started. New invoice will be generated shortly!",
          "success"
        );
        setSelectedItems([]);
        props.setIsRefreshInvoices(!props.isRefreshInvoices);
        //fetchInvoiceList();
      } else {
        props.toastLoaderUpdate(
          toastId,
          "Invoice regenerating failed!",
          "error"
        );
      }
    } catch (error) {
      props.toastLoaderUpdate(toastId, "Internal Server Error!", "error");
    }
  }

  async function sendInvoice() {
    let toastId = props.toastLoader("Please wait...");
    let invoiceToSend = null;
    invoices.forEach((invoice) => {
      if (invoice.invoiceNumber === selectedItems[0]) {
        invoiceToSend = invoice;
      }
    });
    try {
      var response = invoiceToSend.invoiceVersion === "v1" ? await invoiceSend(invoiceToSend) : await invoiceSendV2(invoiceToSend);
      if (response) {
        if (response.status === 200) {
          props.toastLoaderUpdate(
            toastId,
            "Invoice sent successfully!",
            "success"
          );
          setSelectedItems([]);
          props.setIsRefreshInvoices(!props.isRefreshInvoices);
        } else if (response.status === 500) {
          props.toastLoaderUpdate(toastId, "Something went wrong", "error");
        } else {
          let message = response.data.response || "Something went wrong";
          props.toastLoaderUpdate(toastId, message, "error");
        }
      } else {
        props.toastLoaderUpdate(toastId, "Something went wrong", "error");
      }
    } catch (error) {
      props.toastLoaderUpdate(toastId, "Internal Server Error", "error");
    }
  }

  async function attachPdfToQB(invoiceDetails) {
    let toastId = props.toastLoader("Please wait...");
    var response = await attachPdfService(invoiceDetails);
    if (typeof response == "undefined") {
      props.toastLoaderUpdate(toastId, "something went wrong", "error");
    } else if (response && response.status === 200) {
      props.toastLoaderUpdate(toastId, "PDF Attached successfully!", "success");
      setSelectedItems([]);
      props.setIsRefreshInvoices(!props.isRefreshInvoices);
      //fetchInvoiceList();
    } else {
      let message = response.data.response
        ? response.data.response
        : "something went wrong";
      props.toastLoaderUpdate(toastId, message, "error");
    }
  }

  async function fetchInvoiceItemPreview(invoiceItemToPreview) {
    setIsPdfViewLoader(true);
    setIsPdfViewOpen(true);
    let response = await invoiceItemPreviewV2(invoiceItemToPreview);
    if (response && response.status === 200) {
      if (response.data) {
        var dataToArrayBuffer = new Uint8Array(
          response.data
        );
        var linkToPdf = URL.createObjectURL(
          new Blob([dataToArrayBuffer], {
            type: "application/pdf",
          })
        );
        setLinkToPdfData(linkToPdf);
        setIsPdfViewLoader(false);
      } else {
        props.toastError("Failed to view Invoice!");
      }
    } else if (response.status === 404) {
      setIsPdfViewOpen(false);
      props.toastError("Failed to view Invoice! Please Regenerate");
    }
  }

  const renderManuelShiftButtons = (status) => {
    return (
      <>
        {!["send-to-customer","payment-received"].includes(status) && (
          <Button
            className="user-button"
            disabled={selectedItems.length != 0 ? false : true}
            onClick={() => {
              setInvoiceStatus("send-to-customer");
              openConfirmationModal(
                `Status of ${selectedItems.length} invoices will be updated to "Sent To Customer".`,
                "save",
                "status"
              );
            }}
          >
            <label className="labels">Send To Customer</label>
          </Button>
        )}
        {status != "payment-received" && (
          <Button
            className="user-button"
            disabled={selectedItems.length != 0 ? false : true}
            onClick={() => {
              setInvoiceStatus("payment-received");
              openConfirmationModal(
                `Status of ${selectedItems.length} invoices will be updated to "Payment Received".`,
                "save",
                "status"
              );
            }}
          >
            <label className="labels">Payment Received</label>
          </Button>
        )}
        {!["on-hold","payment-received"].includes(status) && (
          <Button
            className="user-button custom-size-button"
            disabled={selectedItems.length != 0 ? false : true}
            onClick={() => {
              setInvoiceStatus("on-hold");
              openConfirmationModal(
                `Status of ${selectedItems.length} invoices will be updated to "On Hold".`,
                "save",
                "status"
              );
            }}
          >
            <label className="labels">On Hold</label>
          </Button>
        )}
        <Button
          className="user-button custom-size-button"
          disabled={selectedItems.length != 0 ? false : true}
          onClick={() => {
            setInvoiceStatus("pending");
            openConfirmationModal(
              `Are you sure you want to move the ${selectedItems.length} invoices to pending status?`,
              "save",
              "status"
            );
          }}
        >
          <label className="labels">Move To Pending</label>
        </Button>
        <Button
          className="user-button custom-size-button"
          disabled={selectedItems.length != 0 ? false : true}
          onClick={() => {
            setInvoiceStatus("cancelled");
            openConfirmationModal(
              `Are you sure you want to cancel the ${selectedItems.length} invoices?`,
              "save",
              "status"
            );
          }}
        >
          <label className="labels">Cancel</label>
        </Button>
      </>
    );
  };

  function setInvoicesList() {
    return currentInvoices.map((obj) => {
      return (
        <tr
          key={obj.invoiceNumber}
          className={"invoice-table-row"}
          style={{
            backgroundColor: selectedItems.includes(obj.invoiceNumber)
              ? ACTIVEROWCOLOR
              : "",
          }}
          onClick={() => {
            selectedItems.length > 1
              ? setSelectedItems([obj.invoiceNumber])
              : selectedItems[0] === obj.invoiceNumber
              ? setSelectedItems([])
              : setSelectedItems([obj.invoiceNumber]);
          }}
        >
          <td>
            {(
              <FormCheck
                name={obj.invoiceNumber}
                checked={selectedItems.includes(obj.invoiceNumber)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedItems([...selectedItems, obj.invoiceNumber]);
                  } else {
                    let updatedList = selectedItems.filter(function(item) {
                      return item !== obj.invoiceNumber;
                    });
                    setSelectedItems(updatedList);
                  }
                }}
              />
            )}
          </td>
          <td
            className={
              obj.quickbookInvoiceId
                ? "table-cells-content font-bold"
                : "table-cells-content"
            }
          >
            {customViewFormatDate(obj.invoiceDate)}
          </td>
          <td
            className={
              obj.quickbookInvoiceId
                ? "table-cells-content font-bold"
                : "table-cells-content"
            }
          >
            {obj.accountName}
          </td>
          <td
            className={
              obj.quickbookInvoiceId
                ? "table-cells-content font-bold"
                : "table-cells-content"
            }
          >
            {obj.invoiceFrequency}
          </td>
          <td
            className={
              obj.quickbookInvoiceId
                ? "table-cells-content font-bold"
                : "table-cells-content"
            }
          >
            {"$" + obj.invoiceAmount.toFixed(2)}
          </td>
          <td className="table-cells-content">
            <a
              href="#"
              onClick={() => fetchInvoiceItemPreview(obj)}
              style={{ textDecoration: "none" }}
            >
              Preview Invoice
            </a>
          </td>
          <td
            className={
              obj.quickbookInvoiceId
                ? "table-cells-content font-bold"
                : "table-cells-content"
            }
          >
            {obj.subStatus}
          </td>
          {props.invoiceType === "in-progress" && (
            <td className="table-cells-content">
              <Button
                className={
                  obj.isPdfAttached ? "active-button" : "inactive-button"
                }
                disabled={obj.isPdfAttached}
                onClick={() => {
                  attachPdfToQB(obj);
                }}
              >
                <label className="labels">
                  {obj.isPdfAttached ? "PDF Attached" : "Attach PDF"}
                </label>
              </Button>
            </td>
          )}
        </tr>
      );
    });
  }

  function openConfirmationModal(message, icon, type) {
    setDialogBox({
      isConfirmationDialogBox: true,
      dialogBoxMessage: message,
      dialogBoxIcon: icon,
      isDialogboxOpen: true,
      type: type,
    });
  }

  function closeConfirmationDialogBox() {
    setDialogBox({
      isDialogboxOpen: false,
    });
    setSelectedItems([]);
    fetchInvoiceList();
  }

  function closePdfModal() {
    setSelectedItems([]);
    setIsPdfViewLoader(false);
    setIsPdfViewOpen(false);
  }

  const refreshInvoicesList = async () => {
    await fetchInvoiceList();

    props.toastSuccess("Invoices reloaded");
  }

  return (
    <div style={{ position: "relative" }}>
      <Row>
        <h1 className="title-card" style={{ width: "85%" }}>Invoice Management</h1>
      </Row>
      <Row className="">
        <Col className="col-right-space ">
          <Row>
            <div className="d-flex align-items-center my-4">
              <DatePicker
                className="input-field date-field-width w-full"
                placeholderText={"Date Range"}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
              />

              <Button
                className="user-button"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
                disabled={startDate === null || endDate === null}
                onClick={() => {
                  fetchInvoiceList();
                }}
              >
                View
              </Button>
              <Button
                className="user-button"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
                disabled={startDate === null || endDate === null}
                onClick={() => {
                  setDateRange([null, null]);
                }}
              >
                Clear
              </Button>
            </div>
          </Row>
        </Col>
        <Col sm={8} className="col-right-space ">
          <div className="top-buttons-div mb-0">
            {props.invoiceType === "pending" && (
              <>
                <Button
                  className="user-button custom-size-button"
                  disabled={tokenValidationFlag}
                  onClick={() => {
                    if (selectedItems.length === 1) {
                      window.open(QB_URL + ORG_ID, '_blank');
                    }
                  }}
                >
                  <label className="labels">Connect To Quickbook</label>
                </Button>
                <Button
                  className="user-button custom-size-button"
                  disabled={selectedItems.length === 1 ? false : true}
                  onClick={() => regenerateInvoice()}
                >
                  <label className="labels">Regenerate Invoice</label>
                </Button>
                <Button
                  className="user-button custom-size-button"
                  style={{ width: "fit-content" }}
                  onClick={() =>{
                    navigate("/invoice-now");
                  }
                }
                >
                  <label className="labels">Invoice Now</label>
                </Button>
              </>
            )}
          </div>
          <div className="top-buttons-div mt-0">
            {props.invoiceType === "pending" && (
              <>
                <Button
                  className="user-button custom-size-button"
                  disabled={selectedItems.length === 1 ? false : true}
                  onClick={() => sendInvoice()}
                >
                  <label className="labels">Send To QB</label>
                </Button>
                <Button
                  className="user-button custom-size-button"
                  disabled={selectedItems.length != 0 ? false : true}
                  onClick={() => {
                    setInvoiceStatus("send-to-customer");
                    openConfirmationModal(
                      `Status of ${selectedItems.length} invoices will be updated to "Sent To Customer".`,
                      "save",
                      "status"
                    );
                  }}
                >
                  <label className="labels">Send To Customer</label>
                </Button>
                <Button
                  className="user-button custom-size-button"
                  disabled={selectedItems.length != 0 ? false : true}
                  onClick={() => {
                    setInvoiceStatus("cancelled");
                    openConfirmationModal(
                      `Are you sure you want to cancel the ${selectedItems.length} invoices?`,
                      "save",
                      "status"
                    );
                  }}
                >
                  <label className="labels">Cancel</label>
                </Button>
              </>
            )}
            {props.invoiceType === "cancelled" && (
              <Button
                className="user-button custom-size-button"
                disabled={selectedItems.length != 0 ? false : true}
                onClick={() => {
                  setInvoiceStatus("deleted");
                  openConfirmationModal(
                    `Are you sure you want to delete the ${selectedItems.length} invoices?`,
                    "delete",
                    "status"
                  );
                }}
              >
                <label className="labels">Delete Invoice</label>
              </Button>
            )}
            {props.invoiceType != "pending" &&
              props.invoiceType != "cancelled" &&
              renderManuelShiftButtons(props.invoiceType)}
          </div>
        </Col>
      </Row>
      <Row>
        <Col style={{paddingBottom: '0.6rem', display: "flex", justifyContent: "end"}} className="col-right-space ">
          <Button
            className="user-button custom-size-button"
            onClick={async () => {
              refreshInvoicesList();
            }}
          >
            <label className="labels">Refresh</label>
          </Button>
        </Col>
      </Row>
      <Row style={{ maxHeight: "60vh", overflowY: "scroll" }}>
        <Form>
          <Table className="table">
            <thead>
              <tr className="table-heading">
                <th style={{ width: "10vh" }}>
                  <div className="table-fields-heading">
                    {(
                      <FormCheck
                        checked={
                          selectedItems.length === currentInvoices.length &&
                          currentInvoices.length > 0
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            let newList = [];
                            currentInvoices.forEach((item) =>
                              newList.push(item.invoiceNumber)
                            );
                            setSelectedItems(newList);
                          } else {
                            setSelectedItems([]);
                          }
                        }}
                      />
                    )}
                  </div>
                </th>
                {Object.entries(fieldNamesAndLabels).map(
                  ([fieldName, labelIs]) => {
                    return (
                      <th key={fieldName} style={{ width: "20%" }}>
                        <TableHeading
                          labelIs={labelIs}
                          fieldName={fieldName}
                          setSortingType={setSortingType}
                          setCurrentSortingField={setCurrentSortingField}
                          sortField={sortField}
                          sortingType={sortingType}
                          currentSortingField={currentSortingField}
                        />
                      </th>
                    );
                  }
                )}
                {props.invoiceType === "in-progress" && (
                  <th>
                    <TableHeading
                      labelIs={"PDF"}
                      fieldName={"invoicePdf"}
                      setSortingType={setSortingType}
                      setCurrentSortingField={setCurrentSortingField}
                      sortField={sortField}
                      sortingType={sortingType}
                      currentSortingField={currentSortingField}
                    />
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {invoices.length > 0 ? (
                setInvoicesList()
              ) : (
                <tr>
                  <td colSpan="6" className="invoices_view no-data-found">
                    {loading ? <Loader loading={loading} /> : "No data found"}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Form>
      </Row>

      <DialogBoxModal
        show={dialogBox.isDialogboxOpen}
        name={dialogBox.dialogboxName}
        onHide={() => closeConfirmationDialogBox()}
        onEvent={updateInvoiceStatus()}
        message={dialogBox.dialogBoxMessage}
        icon={dialogBox.dialogBoxIcon}
        isConfirmationDialogBox={dialogBox.isConfirmationDialogBox}
        buttonName="Yes"
        secondaryButtonName="No"
      />

      <PdfViewModal
        show={isPdfViewOpen}
        linkToPdfData={linkToPdfData}
        loader={isPdfViewLoader}
        onHide={() => closePdfModal()}
      />

      {invoices.length > 0 && isLoadPagination ? (
        <Pagination
          items={invoices}
          currentItems={setCurrentInvoices}
          itemsPerpage={itemsPerpage}
          itemOffset={setItemOffset}
          endOffset={setEndOffset}
          clearSelectedItems={setSelectedItems}
        />
      ) : null}
    </div>
  );
}

export default InvoicesList;
