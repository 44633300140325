export const BASEPATH = process.env.REACT_APP_API_ENDPOINT;
export const ORG_ID = 1;
export const FREQUENCY = ["daily", "weekly","biweekly", "monthly", "yearly"];
export const WASH_FREQUENCY = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "biweekly", label: "Biweekly" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
    { value: "30Days", label: "30 Days" },
    { value: "7Days", label: "7 Days" },
  ];
export const UNIT_OF_MEASUREMENT = ["quantity", "hours"];
export const ACTIVEROWCOLOR = "rgb(196 220 255)";
export const QB_URL = process.env.REACT_APP_QB_API;
export const DELIMITER = "#~#";
